
import CloudFun, { Condition, defineComponent, Operator, ref } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import SelectBox, { SelectBoxOptions } from '@/cloudfun/components/SelectBox.vue'
import formatDate from 'xe-utils/toDateString';
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import { VueUploadItem } from "vue-upload-component";
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import "@/global-components/ckeditor/styles.css";
import { commafy } from "xe-utils";

export default defineComponent({
  components: {
    Grid,
    FileUploader,
    SelectBox,
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    var printColumns = [
      { field: 'Name' },
      { field: 'Description' }
    ]
    const uploader = ref<any>({});

    const gridOptions: GridOptions = {
      stripe: false,
      title: '影音專區',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      multiselect: false,
      printConfig: { sheetName: '影音專區清單', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '影音專區清單', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [{
        field: 'Title',
        title: '名稱',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
        treeNode: false,
        editRender: { name: '$input', immediate: true, attrs: { type: 'text' } },
        filters: [{ checked: false, label: '包含' }],
        slots: { filter: 'filter-name' }
      },
      { field: 'PublishedDate', title: '發布日期', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => formatDate(cellValue, 'yyyy/MM/dd') },
      { field: 'Published', title: '已發布', showHeaderOverflow: true, showOverflow: true, sortable: true, align: 'center', formatter: ({ cellValue }) => cellValue ? '是' : '否' },
      { field: 'Ordinal', title: '排序', showHeaderOverflow: true, showOverflow: true, sortable: true, align: 'center' },
      { field: 'ViewCount', title: '檢視次數', showHeaderOverflow: true, showOverflow: true, sortable: true, align: 'right', formatter: ({ cellValue }) => `${commafy(cellValue)}` },
      ],
      promises: {
        query: model ? (params) => {
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'Ordinal', order: 0 }, { column: 'CreatedTime', order: 1 });
          }
          // if (params.condition) params.condition.and('ParentId', Operator.IsNull)
          // else params.condition = new Condition('ParentId', Operator.IsNull)
          return model.dispatch('media/query', params)
        } : undefined,
        queryAll: model ? () => model.dispatch('media/query') : undefined,
        save: model ? (params) => model.dispatch('media/save', params) : undefined
      },
      modalConfig: { width: "70%", height: "700", showFooter: true },
      // treeConfig: { children: 'Children' }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 120,
      titleAlign: 'right',
      items: [
        {
          field: "Photo.Uri",
          // title: "圖片",
          span: 24,
          slots: { default: "column-photo-and-remark" }
        },
        {
          field: 'Title',
          title: '名稱',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Number',
          title: '編號',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '系統自動產生', clearable: true, disabled: 'true' }, attrs: { type: 'text' } }
        },
        {
          field: 'Author',
          title: '作者',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'PublishedDate',
          title: '發布日期',
          span: 12,
          // titleWidth: 80,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', clearable: true } }
        }, {
          field: 'StartDate',
          title: '發布開始日',
          span: 12,
          // titleWidth: 80,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', clearable: true } }
        }, {
          field: 'EndDate',
          title: '發布結束日',
          span: 12,
          // titleWidth: 80,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', clearable: true } }
        },
        {
          field: 'Published',
          title: '已發布',
          span: 12,
          // titleWidth: 80,
          itemRender: { name: '$select', options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
        },
        {
          field: 'Ordinal',
          title: '排序',
          span: 12,
          // titleWidth: 80,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' }, }
        },
        {
          field: 'ViewCount',
          title: '檢視次數',
          span: 12,
          // titleWidth: 80,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', disabled: 'true' }, }
        },
        {
          title: '關鍵字',
          span: 24,
          slots: { default: 'column-keyword' }
        },
        {
          field: 'LinkUrl', title: 'YouTube ID', span: 24,
          itemRender: { name: '$input', props: { placeholder: '請輸入YouTube ID, 例如: Tf9tisPpNp4', clearable: true } },
        },
        { span: 24, slots: { default: 'html-editer' } },
      ],
      rules: {
        Title: [{ type: 'string', required: true }],
        PublishedDate: [{ required: true }],
        Author: [{ type: 'string', required: true }],
        LinkUrl: [{ type: 'string', max: 2000, required: true }],
      }
    }

    // function schemaCustomization(editor: any) {
    //   editor.model.schema.extend("$block", {
    //     allowAttributes: "classList"
    //   });
    //   editor.conversion.attributeToAttribute({
    //     model: {
    //       key: "classList"
    //     },
    //     view: {
    //       key: "class"
    //     }
    //   });
    // }

    const classicEditor = ClassicEditor;
    const editorConfig = {
      extraPlugins: [UploadAdapterPlugin],
      toolbar: {
        shouldNotGroupWhenFull: true,
        items: [
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "alignment",
          "bulletedList",
          "numberedList",
          "outdent",
          "indent",
          "highlight",
          "insertTable",
          "|",
          "link",
          "blockQuote",
          "imageInsert",
          "mediaEmbed",
          // 'codeBlock',
          "htmlEmbed",
          "|",
          "|",
          "undo",
          "redo",
          "sourceEditing"
        ]
      },
      removePlugins: ["Markdown"],
      image: {
        toolbar: [
          "imageTextAlternative",
          "imageStyle:full",
          "imageStyle:side",
          "linkImage"
        ]
      },
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableCellProperties",
          "tableProperties"
        ]
      },
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph"
          },
          {
            model: "heading1",
            view: { name: "h1", classes: "font-bold text-xl" },
            title: "Heading 1",
            class: "ck-heading_heading1"
          },
          {
            model: "heading2",
            view: { name: "h2", classes: "font-bold text-base" },
            title: "Heading 2",
            class: "ck-heading_heading2"
          }
        ]
      }
    };

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => model!.dispatch("keyword/find", value), // eslint-disable-line
        query: (params) => {
          if (params.condition) params.condition.and('Published', Operator.Equal, true).and('Type', Operator.Equal, 2)
          else params.condition = new Condition('Published', Operator.Equal, true).and('Type', Operator.Equal, 2)
          return model!.dispatch("keyword/query", params)
        },
        insert: (value) => {
          var data = { entity: { Name: value.Name, Type: 2, Published: true } };
          console.log(data);
          return model!.dispatch("keyword/insert", data)
        },
      },
    };

    return {
      model,
      grid,
      selectorOptions,
      gridOptions,
      formOptions,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/files`,
      uploader,
      editorConfig,
      classicEditor,
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    },
    async uploaderFilter (current: VueUploadItem, original: VueUploadItem, prevent: any) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    },
    async onGridEdit(row: any, callback: any) {
      const entity = row.Id
        ? await this.$model.dispatch("media/find", row.Id)
        : undefined;
      if (entity) {
        if (entity.Keywords && entity.Keywords.length) {
          entity.KeywordIds = entity.Keywords.map((e: any) => e.KeywordId);
        } else {
          entity.KeywordIds = [];
        }
        Object.assign(row, entity);
      }
      callback();
    }
  }
})
